body {
  color: #333333;
  font-family: "Roboto",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.4;

  padding: 0;
  margin: 0;
  overflow-x: hidden;
  position: relative;
}

button, input, select, textarea {
  font-family: "Roboto",sans-serif;
  font-size: 14px;
  line-height: 1.4;
}

a,
button {
  outline: none !important;
}

@media(max-width: 991px) {
  .container {
    width: auto;
  }
}

.map { height: 500px; border-radius: 5px; }

@media (max-width : 767px) {
  .map { height: 250px; }
}

.width-100 {
  width: 100%;
}

.well {
  border: 0;
  margin-bottom: 0;
}

.logo-contacts {
  margin: 10px 20px;
}

.img-style {
  height: auto;
  width: 100%;
}

.scroller {
  background-color: #fff;
  border-radius: 50%;
  bottom: 160px;
  cursor: pointer;
  display: none;
  height: 32px;
  position: fixed;
  right: 25px;
  transition: all 0.5s ease 0s;
  width: 32px;
  z-index: 500;
}
.scroller i {
  color: #e1704b;
  font-size: 39px;
  left: -1px;
  position: absolute;
  top: -4px;
}

/*.call-me-pleas {
  background-color: #39a9a4;
  border-radius: 50%;
  bottom: 60px;
  cursor: pointer;
  height: 80px;
  position: fixed;
  right: 21px;
  transition: all 0.5s ease 0s;
  width: 80px;
  z-index: 510;
}*/

.call-me-pleas {
  background-color: #39a9a4;
  border-radius: 50%;
  bottom: 60px;
  cursor: pointer;
  height: 80px;
  position: fixed;
  right: 21px;
  transition: all 0.5s ease 0s;
  width: 80px;
  z-index: 510;
}

/*.call-me-pleas i {
  color: #ffffff;
  font-size: 27px;
  left: 10px;
  position: absolute;
  top: 5px;
}*/

.call-me-pleas i {
  color: #ffffff;
  font-size: 40px;
  left: 25px;
  position: absolute;
  top: 17px;
}

.gps_ring {
  -webkit-animation: 1s ease-out 0s normal none infinite running pulsate; /* Safari 4+ */
  -moz-animation:    1s ease-out 0s normal none infinite running pulsate; /* Fx 5+ */
  -o-animation:      1s ease-out 0s normal none infinite running pulsate; /* Opera 12+ */
  animation:         1s ease-out 0s normal none infinite running pulsate; /* IE 10+, Fx 29+ */

/*
  border: 4px solid #39a9a4;
  border-radius: 30px;
  bottom: 50px;
  height: 90px;
  opacity: 0;
  position: fixed;
  right: 11px;
  width: 90px;
*/

  border: 4px solid #39a9a4;
  border-radius: 50%;
  bottom: 56px;
  height: 90px;
  opacity: 0;
  position: fixed;
  right: 16px;
  width: 90px;
}
@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
@-moz-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
@-o-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
@keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

.padding-top { padding-top: 100px; }
@media(max-width: 1024px) {
  .padding-top { padding-top: 30px; }
}

.padding-block-2-0 {
  padding-top: 20px;
}

.padding-block-0-2 {
  padding-bottom: 20px;
}

.padding-block-1-0 {
  padding-top: 10px;
}

.padding-block-1-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-block-0-1 {
  padding-bottom: 10px;
}

.padding-block-1-2 {
  padding-top: 10px;
  padding-bottom: 20px;
}

.padding-block-2-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-block-2-0 {
  padding-top: 20px;
}

.padding-block-2-1 {
  padding-top: 20px;
  padding-bottom: 10px;
}

.padding-vert-15 {
  padding: 0 15px;
}

.padding-vert-left-20 {
  padding-left: 20px;
}

.margin-bottom-5 {
  margin-bottom: 7px;
}

.btn {
  border-radius: 0;
  font-size: 16px;
  padding: 8px 15px;
  transition: all 0.5s ease 0s;
}
@media(max-width: 767px) {
  .btn { font-size: 14px; padding: 6px 10px; }
}

.btn-success {
  background-color: #39a9a4;
  border-color: #39a9a4;
}

.btn-success:focus,
.btn-success:hover,
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
  background-color: #e1704b;
  border-color: #e1704b;
  color: #fff;
}

.btn-warning {
  background-color: #e1704b;
  border-color: #e1704b;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.btn-warning:focus,
.btn-warning:hover {
  background-color: #39a9a4;
  border-color: #39a9a4;
  color: #ffffff;
}

.btn-link:focus,
.btn-link:hover,
.btn-link:active {
  background-color: transparent;
  color: #000000;
  text-decoration: underline;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #39a9a4;
  border-color: #39a9a4;
  color: #fff;
  cursor: default;
  z-index: 3;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin: 0 -20px;
  position: absolute;
}

.register-link {
  background-color: #39a9a4;
  color: #fff;
  text-align: center;
}

.register-link a {
  font-weight: 900;
  color: #fff;
}

.form-control {
  background-color: #ffffff;
  border-color: #eeeeee;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  color: #808080;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  line-height: 20px;
  outline: 0 none;
  padding: 9px 17px 10px 16px;
}
.form-control:focus {
  border-color: #e1704b;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(225, 112, 75, 0.65);
  outline: 0 none;
}

#property-container .form-control {
  background-color: white;
  border: 1px solid #333;
  border-radius: 4px;
  font-size: 100%;
  padding: 0px 5px 0;
  min-height: 32px;
  font-family: "Roboto",sans-serif;
  font-style: normal;
  font-weight: 300;
  cursor: text;
  color: #333;
  outline: 0 none;

}

#property-container .form-control:focus
{
  box-shadow: none;
  border-color: #333;
}

#property-container .form-control input::-moz-placeholder {
  color: #333;
}
#property-container .form-control input::-webkit-input-placeholder {
  color: #333;
}

.my-orders .panel {
  border: 0;
}
.my-orders .panel-heading {
  border-radius: 4px;
  border-bottom: 1px solid #ccc;
}

.my-finish-orders {
  padding: 0;
}
.my-finish-orders .row {
  margin: 0;
}
.my-finish-orders .row > div {
  height: 40px;
  line-height: 40px;
}
.my-finish-orders .panel-title {
  line-height: 40px;
}

.modal-content { border-radius: 0; }

.send-button {
  font-weight: 700;
  padding: 15px 20px;
}

.show-reset-pass-form,
.show-login-form {
  padding: 0;
  font-weight: 900;
  font-size: 14px;
}

.margin-15 { margin: 15px 0; }
@media(max-width: 767px) {
  .margin-15 > div { margin: 10px 0; }
}

.alert {
  border-radius: 0;
  margin: 0;
}

.registred-form input,
.registred-form textarea {
  font-size: 16px;
  line-height: 20px;
  outline: 0 none;
  padding: 9px 17px 10px 16px;
  transition: all 0.35s ease 0s;
  height: 40px;
}

.registred-form .form-group {
  margin-bottom: 22px;
}

.panel-right > div {
  text-align: right;
}

.lang-block {
  width: 100%;
  margin-bottom: 20px;
}
.lang-block .btn {
  float: none !important;
  border-radius: 0;
  padding: 4px 12px;
}
.lang-block .dropdown-menu {
  border-radius: 0;
  margin: 0;
}
.lang-block .dropdown-menu > li > a {
  padding-left: 10px;
  padding-top: 3px;
}

@media(max-width: 767px) {
  .lang-block .dropdown-menu {
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
  }
}

@media(max-width: 767px) {
  .panel-right > div {
    text-align: center;
  }
}

.user-block { text-align: right; }

@media(max-width: 767px) {
  .user-block { text-align: center; }
}

.user-block a {
  font-size: 14px;
  display: inline-block;
  text-align: center;
  padding: 0 15px;
}
@media(max-width: 360px) {
  .user-block a { padding: 0 7px; }
}

.user-block a > i {
  display: block;
}

.cart-empty {
  color: #999;
  text-align: center;
  font-size: 30px;
}
@media(max-width: 767px) {
  .cart-empty { font-size: 20px; }
}

.font-up {
  text-transform: uppercase;
}
.up-first {
  text-transform: capitalize;
}

.flash-messages > section {
  padding: 0 !important;
}

.laguage-block {
  background-color: #eeeeee;
  padding: 2px 10px;
  border-radius: 0 0 4px 4px;
}

.laguage-block img {
  height: 15px;
  margin: 0 2px;
}

.logo-format { text-align: left; }
@media(max-width: 767px) {
  .logo-format { text-align: center; }
}

.logotype img {
  height: 100px;
}

.contact-block {
  display: inline-block;
}

.contact-block div {
  display: inline-block;
}

.text-green {
  color: #39a9a4;
}

.text-gray-contact,
.text-gray-contact a {
  color: #808080;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1;
}
@media(max-width: 767px) {
  .text-gray-contact,
  .text-gray-contact a {
    font-size: 14px;
    line-height: 1.2;
  }
}

.text-gray-contact a:hover,
.text-gray-contact a:active,
.text-gray-contact a:focus {
  color: #e1704b;
  text-decoration: none;
}

/*правим стили чата*/
.sh_btn_bottom_right {
  right: 0 !important;
}

.news {
  font-weight: 400;
}

.shared-buttons #vkshare0 td:nth-child(2) a {
  height: 21px !important;
}

.style-pinterest > span {
  top: -7px;
}

.style-vk {
  display: inline-block;
}

.fb-share-button {
  top: -6px;
}

.orange-point {
    margin-left: 25px;
    position: relative;
}

.orange-point:before {
    color: #e1704b;
    content: "♦";
    font-size: 30px;
    left: -25px;
    line-height: 20px;
    position: absolute;
}

.gray-point {
    margin-left: 15px;
    position: relative;
}

.gray-point:before {
    color: #626262;
    content: "♦";
    font-size: 30px;
    left: -25px;
    line-height: 20px;
    position: absolute;
}

.slider-index {
  height: 636px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

#property-container .select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #333;
  border-radius: 4px;
  cursor: text;
}

.banner {
  margin-left: 20px;
}

.banner .row a img {
  width: 250px;
  height: 250px;
}

.banner .row {
  margin: 20px 0;
}

@media (max-width: 767px) {
  .banner {
    margin-left: 40px;
  }
}