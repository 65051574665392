.products-search-block {
  background-color: #eeeeee;
}

.select2-container .select2-selection--single {
  height: 40px!important;
}

.select2-selection__arrow {
  top: 7px!important;
}

.select2-selection__rendered {
 margin-top: 7px;
}

@media(min-width: 992px) {
  .products-search-block .search {
    padding-left: 0 !important;
  }
}

@media(max-width: 767px) {
  .products-search-block { margin: 0 15px; }
}

.products-search-block input,
.products-search-block select {
  border-radius: 0;
}

.products-menu-block {
  padding: 10px;
  background-color: #eeeeee;
  /*margin-bottom: 20px;*/
}
@media(max-width: 767px) {
  .products-menu-block { margin: 0 15px; }
}

.product-sliders-large {
  margin-top: 20px;
}

.product-sliders-small .carousel-inner > .item > img,
.product-sliders-small .carousel-inner > .item > a > img,
.product-sliders-large .carousel-inner > .item > img,
.product-sliders-large .carousel-inner > .item > a > img {
  width: 100% !important;
}

@media(max-width: 767px) {
  .product-sliders-small { margin: 0 15px; }
  .product-sliders-large { margin: 20px 15px 0 15px; }
}

.catalog {
  margin: 0;
}

.catalog li {
  padding: 3px 0;
  cursor: pointer;
}

.catalog li ul {
  padding-left: 10px;
}

.catalog .text-black-h3 {
  font-size: 16px;
}

.thumbnail .caption {
  display: inline-block;
  height: 210px;
  overflow: hidden;
}

.caption-footer {
  border-top: 1px dotted #858585;
  padding-top: 4px;
}

.caption-footer .shopping-cart-block {
  text-align: center;
  margin-bottom: 4px;
}
.caption-footer .card-price,
.caption-footer .btn {
  width: 100%;
}

/* Карточка превью изображения продукции */
.file-preview-body { background-color: #fff; }
.file-preview-body .file-preview-frame { margin: 0; margin-right: 5px; }
.file-preview-frame > img { width: auto; height: 140px; }
.file-preview-frame .file-thumbnail-footer { margin-top: 5px; display: block; }
.file-thumbnail-footer > div { line-height: 22px; margin: 0 5px; }

.row-none-margin {
  margin: 0;
}

.card-price,
.card-white-price {
  border-radius: 4px;
  display: inline-block;
  font-size: 18px;
  height: 30px;
}
.card-price {
  background: #fff3b5 none repeat scroll 0 0;
  padding: 7px 7px 5px;
  line-height: 18px;
  font-weight: 600;
  width: auto;
}

.card-white-price {
  line-height: 34px;
}

.padding-horizon {
  padding: 0 15px;
  margin-bottom: 15px;
}

.modal-footer .card-price {
  background: #fff3b5 none repeat scroll 0 0;
  border-radius: 0 0 4px 4px;
  position: relative;
  top: -15px;
}

.modal-body select.form-control {
  border-radius: 4px;
  padding: 2px 12px 2px;
  height: 31px;
}

.my-orders .panel .card-price {
  background: #fff3b5 none repeat scroll 0 0;
  border-radius: 0;
  height: auto;
  margin-right: -15px;
  padding: 0 7px;
  line-height: 40px;
  border-right: 1px solid #ccc;
}

.card-price-text {
  position: relative;
  top: -15px;
  display: inline;
  font-size: 18px;
}

.card-price div {
  display: inline-block;
}

.total-price { display: inline-block; }

.card-price .card-price-uah,
.card-white-price .card-price-uah {
  font-size: 0.77777em;
  line-height: 1em;
  font-weight: 300;
}

.shopping-cart input {
  border: 1px solid #c2c2cc;
  border-radius: 3px;
  box-shadow: 3px 3px 1px 0 #eeeeef inset;
  display: inline-block;
  font-size: 18px;
  height: 30px;
  resize: none;
  text-align: center;
  vertical-align: bottom;
  width: 45px;
}

.shopping-cart div {
  display: inline-block;
}

.in-shoping-cart {
  vertical-align: super;
}

.in-shoping-cart .btn {
  height: 34px;
  padding: 6px;
}

.in-shoping-cart .fa {
  font-size: 27px;
  line-height: 20px;
}

@media(max-width: 767px) {
  .in-shoping-cart .fa {
    font-size: 23px;
    line-height: 20px;
  }
}

.shopping-cart-badge {
  background: #e1704b none repeat scroll 0 0;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: -6px;
  width: 20px;
  z-index: 1;
}

.cart-quantity {
  padding: 0 15px;
}

.cart-quantity-minus,
.cart-quantity-plus { padding: 0; }

.cart-quantity-minus:hover,
.cart-quantity-plus:hover { color: #d9534f; }

.cart-quantity-minus i,
.cart-quantity-plus i { font-size: 18px; }

/* /Карточка превью изображения продукции */





/* Редактор меню */
.menu {
  counter-reset: li;
  font-size: 16px;
  list-style: outside none none;
  margin-bottom: 0;
  margin-left: -10px;
  padding: 0;
}
.menu-item {
  cursor: pointer;
  color: #1873b4;
  margin: 6px;
}
.menu-empty {
  min-height: 5px;
}
.menu-empty > .sub-menu {
  padding-left: 20px;
}
.sub-menu .fa-angle-down,
.sub-menu .fa-angle-right {
  position: relative;
  top: 4px;
  padding: 0;
  margin: 0;
}
.sub-menu .fa-angle-right {
  text-align: center;
  width: 11px;
}

.fake-width {
  width: 10px;
  display: inline-block;
}
/* /Редактор меню */

.empty-products {
  padding: 8px;
  font-size: 17px;
  font-weight: 900;
  text-align: center;
}

.product-card h4,
.product-list h4 {
  margin-bottom: 5px;
  margin-top: 0;
}

.product-card .row,
.product-list .row {
  position: relative;
}

.product-card hr,
.product-list hr {
  margin-top: 0;
}

.delete-product {
  background-color: #ea7954 !important;
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  opacity: 1;
  position: absolute;
  right: 15px;
  text-align: center;
  text-shadow: none;
  top: -10px;
  width: 25px;
  z-index: 100;
}

.products-cards .panel {
  margin-bottom: 5px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 0;
}

.products-cards .panel .panel-body {
  padding: 0;
}

.products-cards .panel .product-title,
.products-cards .panel .card-price,
.shopping-cart-block .card-price-block .card-price {
  border-radius: 0;
  font-size: 16px;
  padding: 9px 15px;
  line-height: 1.42857;
  vertical-align: middle;
  height: auto;
}
@media(max-width: 767px) {
  .products-cards .panel .product-title,
  .products-cards .panel .card-price,
  .shopping-cart-block .card-price-block .card-price {
    font-size: 14px; padding: 7px 10px;
  }
}

.products-cards .panel .text-black-h3 {
  line-height: 1;
}

.products-cards .panel .card-price,
.products-cards .panel .card-sum-price {
  height: auto;
}

@media (min-width : 992px) and (max-width : 1199px) {
  .products-cards .thumbnail .btn { padding: 8px 11px; }
}

.thumbnail.reload {
  height: 503px;
}

.thumbnail.reload > .reload-ico {
  margin-top: 185px;
  max-width: 180px;
  display: inline-block;
}

.sum-price {
  display: inline-block;
}

.orders-status {
  text-align: center;
  border-radius: 0 4px 4px 0;
}
@media (max-width : 767px) {
  .orders-status { text-align: right; }
}

.orders-status.accepted {
  background-color: #39a9a4;
  color: #fff;
}
.orders-status.closed {
  background-color: #e1704b;
  color: #fff;
}

.black-a {
  color: #333333;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.black-a:hover,
.black-a:active,
.black-a:focus {
  color: #e1704b;
  font-style: normal;
  font-weight: 400;
  text-decoration: underline;
}

.not-available {
  padding: 10px;
  background-color: #eee;
  text-align: center;
  font-size: 16px;
}

.jq-ry-container {
  padding: 0 !important;
  display: inline-block !important;
}

.catalog .btn-group {
  width: 100%;
}

.catalog .btn-group .dropdown-toggle {
  width: 100%;
}

.catalog .btn-group .dropdown-menu {
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.catalog .btn-group .dropdown-menu>li {
  padding: 0;
}

.catalog .btn-group .dropdown-menu>li>a {
  padding: 6px 20px;
}
