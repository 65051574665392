.welcome-text,
.title-orange {
  color: #333333;
  font-size: 60px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1;

  padding: 0;
  margin: 0;
}

.title-orange {
  color: #e1704b;
}


.related-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: center;
}

@media(max-width: 1023px) {
  .welcome-text,
  .title-orange {
    font-size: 48px;
    line-height: 1.2;
  }
}
@media(max-width: 767px) {
  .welcome-text,
  .title-orange {
    font-size: 38px;
    line-height: 1.2;
  }
}

.text-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  display: inline-block;
}
@media(max-width: 767px) {
  .text-16 {
    font-size: 13px;
    line-height: 1.5;
  }
}

.text-black-h2 {
  color: #333333;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.2;
}
@media(max-width: 1023px) {
  .text-black-h2 {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 1.2;
  }
}
@media(max-width: 767px) {
  .text-black-h2 {
    font-size: 30px;
    letter-spacing: 0;
    line-height: 1.2;
  }
}

.text-black-h3 {
  color: #333333;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  text-decoration: none;
  display: inline-block;
}

.text-black-h3:hover,
.text-black-h3:active,
.text-black-h3:focus,
.text-black-h3.active {
  color: #e1704b;
  text-decoration: none;
}
@media(max-width: 1023px) {
  .text-black-h3 {
    font-size: 18px;
    line-height: 1.1;
  }
}
@media(max-width: 767px) {
  .text-black-h3 {
    font-size: 17px;
    line-height: 1.2;
  }
}

.contact-block .text-black-h3 {
  font-size: 14px !important;
}

.text-gray-16,
.text-gray-16 a {
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  display: inline-block;
}
@media(max-width: 767px) {
  .text-gray-16,
  .text-gray-16 a {
    font-size: 13px;
    line-height: 1.5;
  }
}

.text-gray-16 a:hover,
.text-gray-16 a:active,
.text-gray-16 a:focus {
  color: #e1704b;
  text-decoration: none;
}


.span-arrow {
  display: inline-block;
  font-size: 15px;
  vertical-align: middle;
  padding-bottom: 5px;
}

.text-green-20,
.text-orange-20 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  text-decoration: none;
  position: relative;
  padding: 0 22px 0 0;
  transition: all 0.5s ease 0s;
}

.text-green-20 {
  color: #39a9a4;
}

.text-orange-20 {
  color: #e1704b;
}

.text-green-20:hover,
.text-green-20:active,
.text-green-20:focus {
  color: #e1704b;
  text-decoration: none;
}

.text-orange-20:hover,
.text-orange-20:active,
.text-orange-20:focus {
  color: #39a9a4;
  text-decoration: none;
}
@media(max-width: 1023px) {
  .text-green-20,
  .text-orange-20 {
    font-size: 18px;
  }
}
@media(max-width: 767px) {
  .text-green-20,
  .text-orange-20 {
    font-size: 16px;
  }
}

/* ЗЕЛЕНАЯ СЕКЦИЯ */

.green-section {
  color: #fff;
  background-color: #39a9a4;
}

.green-section-block {
    margin-bottom: 20px;
}
@media(max-width: 767px) {
  .green-section-block {
    margin-bottom: 10px;
  }
}

.green-img {
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  border: 0 none;
}

.green-section-title {
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
}

.green-section-title:hover,
.green-section-title:active,
.green-section-title:focus {
  color: #000000;
  text-decoration: none;
}

@media(max-width: 767px) {
  .green-section-title {
    font-size: 17px;
    line-height: 1.2;
  }
}

.green-section-link-seo {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;
  display: inline-block;
}

.green-section-link-seo:hover,
.green-section-link-seo:active,
.green-section-link-seo:focus {
  color: #000000;
  text-decoration: none;
}

@media(max-width: 1023px) {
  .green-section-link-seo {
    line-height: 1.3;
  }
}
@media(max-width: 767px) {
  .green-section-link-seo {
    font-size: 14px;
    line-height: 1.3;
  }
}

.green-section-body {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}
@media(max-width: 1023px) {
  .green-section-body {
    line-height: 1.3;
  }
}
@media(max-width: 767px) {
  .green-section-body {
    font-size: 14px;
    line-height: 1.3;
  }
}

/* /ЗЕЛЕНАЯ СЕКЦИЯ */

/* СЕРАЯ СЕКЦИЯ */

.gray-section {
    background-color: #eeeeee;
    padding: 0 25px;
}

.gray-section .text-gray-16 {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}
@media(max-width: 1023px) {
  .gray-section .text-gray-16 {
    font-size: 13px;
  }
}

.gray-img-align {
  margin-bottom: 20px;
  height: 170px;
  display: inline-block;
}

.ornge-section,
.ornge-section-revers {
  background-color: #e1704b;
  color: #fff;
  border: 3px solid #e1704b;
  padding: 15px 30px;
}

.ornge-section-revers {
  background-color: #fff;
  color: #e1704b;
  margin-bottom: 33px;
}

.ornge-section i,
.ornge-section-revers i {
  font-size: 95px;
}
@media(max-width: 1023px) {
  .ornge-section i,
  .ornge-section-revers i {
    font-size: 60px;
  }
}
@media(max-width: 767px) {
  .ornge-section i,
  .ornge-section-revers i {
    font-size: 65px;
  }
}

.ornge-section p,
.ornge-section-revers p {
  font-family: arial,helvetica,sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
}
@media(max-width: 1023px) {
  .ornge-section p,
  .ornge-section-revers p {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 1.2;
  }
}
@media(max-width: 767px) {
  .ornge-section p,
  .ornge-section-revers p {
    font-size: 38px;
    line-height: 1.1;
  }
}

/* /СЕРАЯ СЕКЦИЯ */

/* СЕКЦИЯ ПАРАЛАКСА */

.parallax-window {
  min-height: 475px;
  background: transparent;
  position: relative;
}

.parallax-text-wrap {
  height: 475px;
  left: auto;
  line-height: 475px;
  position: absolute;
  text-align: center;
  top: 0;
}

.parallax-text {
  color: #ffffff;
  font-size: 60px;
  font-weight: 500;
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle;
}

@media(max-width: 767px) {
  .parallax-window {
    min-height: 310px;
  }
  .parallax-text-wrap {
      height: 310px;
      line-height: 310px;
  }
  .parallax-text {
    font-size: 32px;
  }
}

/* /СЕКЦИЯ ПАРАЛАКСА */

/* СЕКЦИЯ ГАЛЕРЕИ */

.articles-block > div {
  padding-bottom: 20px;
}

.articles-block .text-gray-16 {
  padding-top: 30px;
  padding-bottom: 20px;
  display: inline-block;
}

.gallery-row > div {
  margin-bottom: 30px;
}

.gallery-item-box {
  position: relative;
  overflow: hidden;
}

.gallery-row a {
  display: inline-block;
  overflow: hidden;
}

.gallery-row a::before {
  content: "";
  height: 100%;
  left: 15px;
  position: absolute;
  top: -4px;
  transition: all 0.35s ease 0s;
  width: calc(100% - 30px);
  z-index: 1;
  background-color: transparent;
}

.gallery-row a:hover::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.gallery-row a::after {
  color: #ffffff;
  content: "";
  font: 22px/22px FontAwesome;
  height: 24px;
  left: 50%;
  margin: -11px 0 0 -11px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transition: all 0.35s ease 0s;
  width: 27px;
  z-index: 20;
}

.gallery-row a:hover::after {
  opacity: 1;
}

.gallery-row img {
  transform: scale(1);
  transition: all 0.35s ease 0s;
  vertical-align: middle;
  border: medium none;
  margin: 0;
  padding: 0;
}

.gallery-row a:hover img {
  transform: scale(1.2);
}

.fa-hashtag strong{
  font-style: italic;
}

.hashtags {
  display: flex;
  justify-content: center;
}

.hashtags span{
  margin: 20px 10px 0;
}
/* /СЕКЦИЯ ГАЛЕРЕИ */


