.text-orange {
  color: #e1704b;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}

@media(max-width: 1023px) {
  .text-orange {
    font-size: 16px;
    line-height: 1.2;
  }
}

.orange-list ul li {
  line-height: 30px;
  padding-left: 36px;
  position: relative;
  transition: all 0.35s ease 0s;
}

.orange-list ul li::before {
  color: #e1704b;
  content: "";
  font-family: FontAwesome;
  font-size: 11px;
  left: 0;
  line-height: 11px;
  position: absolute;
  top: 10px;
}

.orange-list-a {
  color: #999999;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.orange-list-a:hover,
.orange-list-a:active,
.orange-list-a:focus {
  color: #e1704b;
  font-style: normal;
  font-weight: 400;
  text-decoration: underline;
}